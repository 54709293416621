import React, { useEffect, useState } from "react";

const GTranslate: React.FC = () => {
  const [scriptError, setScriptError] = useState(false);

  useEffect(() => {
    //@ts-ignore
    window.gtranslateSettings = {
      default_language: "en",
      detect_browser_language: true,
      languages: ["en", "fr", "it", "es", "de", "bg", "pl"],
      globe_color: "#66aaff",
      wrapper_selector: ".gtranslate_wrapper",
      horizontal_position: "left",
      vertical_position: "bottom",
    };

    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/globe.js";
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      setTimeout(() => {
        const translateElement = document.querySelector(".gtranslate_wrapper");
        if (!translateElement) {
          console.error("gtranslate_wrapper is missing from the DOM!");
        } else {
          console.log("gtranslate_wrapper found");
        }
      }, 1000);
    };

    script.onerror = () => {
      console.error("Error loading GTranslate script");
      setScriptError(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex justify-center items-center mt-6 w-full">
      <div className="gtranslate_wrapper" style={{ textAlign: "center" }}></div>
    </div>
  );
};

export default GTranslate;
